<template>
  <v-container fluid>
    <v-overlay
      v-if="$apollo.queries.event.loading || $apollo.queries.comments.loading"
      color="white"
      opacity="1"
      absolute
    >
      <v-progress-circular
        :size="64"
        color="primary"
        indeterminate
      />
    </v-overlay>
    <template v-else>
      <v-row dense>
        <v-col :cols="6">
          <v-select
            v-model="filterByCategorySelection"
            :items="uniqueCategories"
            label="Filter By Category"
            outlined
          />
        </v-col>
        <v-col :cols="6">
          <v-select
            v-model="filterByResolutionSelection"
            :items="uniqueResolutions"
            label="Filter By Resolution"
            outlined
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          v-for="comment in filteredComments"
          :key="comment.id"
          :cols="12"
        >
          <report-tag-item
            :event="event"
            :comment="comment"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ReportTagItem from './ReportTagByUserItem'

export default {
  name: 'DashboardReportTag',
  components: { ReportTagItem },
  data: () => ({
    filterByCategorySelection: 'All',
    filterByResolutionSelection: 'All'
  }),
  computed: {
    ...mapState('user', ['claims']),
    uniqueCategories () {
      return ['All', ...this._(this.comments).map('category.display_name').uniq().remove(null), 'Without Category'].sort()
    },
    uniqueResolutions () {
      return ['All', ...this._(this.comments).map('resolution.title').uniq().remove(null), 'Without Resolution'].sort()
    },
    filteredComments () {
      return this.comments
        .filter(this.filterByCategory)
        .filter(this.filterByResolution)
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    filterByCategory (comment) {
      if (this.filterByCategorySelection === 'All') return true
      else if (this.filterByCategorySelection === 'Without Category') return !comment.category
      else return comment.category && comment.category.display_name === this.filterByCategorySelection
    },
    filterByResolution (comment) {
      if (this.filterByResolutionSelection === 'All') return true
      else if (this.filterByResolutionSelection === 'Without Resolution') return !comment.resolution
      else return comment.resolution && comment.resolution.title === this.filterByResolutionSelection
    }
  },
  apollo: {
    event: {
      query: require('@/gql/getEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug
        }
      }
    },
    comments: {
      query: require('@/gql/getCommentsByEvent').default,
      variables () {
        return {
          slug: this.$route.params.eventSlug,
          user_email: this.$route.params.userEmail
        }
      }
    }
  }
}
</script>
